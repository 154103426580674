<template>
   <div>
      <div v-if="!isMobile()" :key="componentKey">
         <div class="ctn-box">
            <div id="saintOuen" class="page-saint-ouen">
               <full-page id="fullpage" ref="fullpage" :options="options">
                  <div id="first-page" class="section" @wheel="wheelGlobal()">
                     <div ref="slide1" class="slide" data-anchor="page1">
                        <section id="saintOuen-1" class="page-section saintOuen-1">
                              <VideoSaintOuen/>
                              <h1 class="title-h1 txt-right" :class="{'anim-slide-right-blur':AnimtxtBlur}">
                                 <span>{{$t('titleSaintouen')['0']}}</span>
                                 <span>{{$t('titleSaintouen')['1']}}</span>
                                 <span>{{$t('titleSaintouen')['2']}}</span>
                              </h1>
                              <div class="scroll-right">
                                 <a href="#" class="btn btn-white btn-scroll" @click.prevent="scrollToNext">  
                                    <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                       <path d="M1 1.20101L10.8995 11.1005L1 21" stroke="#6CB0BD" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                    <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                       <path d="M1 1.20101L10.8995 11.1005L1 21" stroke="#6CB0BD" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                 </a>
                              </div>
                              <div class="bg-mask"></div>
                        </section>
                     </div>

                     <div ref="slide2" class="slide" data-anchor="page2">
                        <section id="saintOuen-2" class="page-section saintOuen-2">
                           <div class="h-100 wallpaper-zoom-inactive">
                              <div class="loc-contain" :class="{'wallpaper-zoom': showZoom}">
                                 <picture class="desk-img"><img class="loadTemp" src="" :dta-src='this.$pathprod+"images/saint-ouen/desk-saintOuen-loc.jpg"' srcset="" :dta-srcset='this.$pathprod+"images/saint-ouen/desk-saintOuen-loc-retina.jpg 2x"' alt="bg-saintOuen-2"></picture>
                                 <div class="loc-bloc">
                                    <div class="loc-bloc-contain">
                                       <span class="loc-bloc-circle"></span> 
                                       <span class="loc-bloc-txt" :class="{'anim-slide-right-blur':AnimLoc}">RHAPSODY</span>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </section>
                     </div>
            
                     <div ref="slide3" class="slide" data-anchor="suivre-ligne">
                        <section id="suivre-ligne" class="page-section saintOuen-3">
                           <div class="map-ctn">
                              <h2 class="title-h2 anim-txt-focus">{{$t('titleSuivrelaligne')['0']}} <br class="d-block">{{$t('titleSuivrelaligne')['1']}}</h2>
                              <figure class="map-harmony">
                                 <img class="desk-img" :src='this.$pathprod+"images/saint-ouen/desk-groupe-01.svg"' alt="bg-saintOuen-3">
                              </figure>
                           </div>
                        </section>
                     </div>

                     <div ref="slide4" class="slide" data-anchor="page4">
                        <section id="saintOuen-4" class="page-section saintOuen-4">
                           <figure class="map-harmony">
                              <img class="loadTemp" src="" :dta-src='this.$pathprod+"images/saint-ouen/batiment-rhapsody.jpg"' srcset="" :dta-srcset='this.$pathprod+"images/saint-ouen/batiment-rhapsody-retina.jpg 2x"' alt="batiment-harmony">
                           </figure>
                        </section>
                     </div>

                     <div ref="slide5" class="slide" data-anchor="saintOuen-5">
                        <section id="saintOuen-5" class="page-section saintOuen-5" @wheel="wheelIntersection">
                           <div class="sct-left">
                              <div class="classParent saintOuen-suivre" data-sect="sect1">
                                 <div class="sct-info p-0">
                                    <h4 class="title-h4 displayBox">{{$t('dataIntersection')['0']}}</h4>
                                    <figure class="mobMap-img">
                                          <img class="loadTemp" src="" :dta-src='this.$pathprod+"images/saint-ouen/saintOuenMap-1.svg"' srcset="" :dta-srcset='this.$pathprod+"images/saint-ouen/saintOuenMap-1.svg"' alt="batiment-harmony-1">
                                    </figure>
                                 </div>
                        
                                 <div class="mob-custom w-100">
                                    <div class="sct-detail">
                                       <p>{{$t('dataIntersection')['1']}}</p>
                                       <p>{{$t('dataIntersection')['2']}}</p>
                                       <p>{{$t('dataIntersection')['3']}}</p>
                                    </div>
                                 </div>

                                 <div class="ctn-circleBrush w-100">
                                    <div class="card-item" data-view="firstCircleAnim">
                                       <div class="img-cercle">
                                          <figure>
                                             <lottie-animation class="brush-logo"
                                                ref="firstCircleAnim"
                                                :animationData="require('@/assets/lottie/brush-saintouen.json')"
                                                :loop="false"
                                                :autoPlay="false"
                                                :speed="3"
                                             />
                                          </figure>
                                       </div>
                                       <div class="card-bg">
                                          <figure>
                                             <img class="loadTemp" src="" :dta-src='this.$pathprod+"images/saint-ouen/saint-ouen-img-mask.png"' srcset="" :dta-srcset='this.$pathprod+"images/saint-ouen/saint-ouen-img-mask.png"' alt="cercle-brush">
                                          </figure>
                                       </div>

                                       <ul class="ctn-parent">
                                          <li v-for="firstItem in firstItems" :key="firstItem.id" class="list-item list-item-custom">
                                             <div class="ctn-item">
                                                <div class="ctn-item-title">
                                                   <span class="title">{{ firstItem.title }}</span>
                                                   <span class="subtitle">{{$t('dataSaintlazare')[firstItem.id-1]}}</span>
                                                </div>
                                                <div class="ctn-item-nbr">
                                                   <div>{{ firstItem.nbr }}</div>
                                                   <div>{{ firstItem.subNbr }}</div>
                                                </div>
                                             </div>
                                          </li>
                                       </ul>
                                    </div>
                                 </div>
                              </div>

                              <!--********************************-->
                              <div id="mobilite-douce" class="classParent saintOuen-mobile" data-sect="sect2">
                                 <div class="w-100">
                                    <div class="sct-info">
                                       <h4 class="title-h4">{{$t('dataIntersection')['4']}}</h4>
                                       <figure class="mobMap-img">
                                             <img class="loadTemp" src="" :dta-src='this.$pathprod+"images/saint-ouen/saintOuenMap-2.svg"' srcset="" :dta-srcset='this.$pathprod+"images/saint-ouen/saintOuenMap-2.svg"' alt="batiment-harmony-2">
                                       </figure>
                                    </div>
                                 </div>
                                 <div class="txt-bloc-1">
                                    <div class="sct-detail">
                                          <h2 class="title-h2">{{$t('dataIntersection')['5']}} <br class="d-block">{{$t('dataIntersection')['6']}}</h2>
                                          <div>
                                             <div class="head-txt">{{$t('dataIntersection')['7']}}</div>
                                             <p>
                                             {{$t('dataIntersection')['8']}}
                                             </p>
                                          </div>

                                          <div>
                                             <div class="head-txt">{{$t('dataIntersection')['9']}}</div>
                                             <p>
                                             {{$t('dataIntersection')['10']}}
                                             </p>
                                          </div>
                                    </div>
                                 </div>

                                 <div class="txt-bloc w-100">
                                    <div class="head-txt">{{$t('dataIntersection')['11']}}</div>
                                    <p>
                                       {{$t('dataIntersection')['12']}}
                                    </p>
                                 </div>
                        
                                 <div class="w-100 mt-90">
                                    <div class="card-item" data-view="secondCircleAnim">
                                       <div class="img-cercle">
                                          <figure>
                                             <lottie-animation class="brush-logo"
                                                ref="secondCircleAnim"
                                                :animationData="require('@/assets/lottie/brush-saintouen.json')"
                                                :loop="false"
                                                :autoPlay="false"
                                                :speed="3"
                                             />
                                          </figure>
                                       </div>
                                       <div class="card-bg">
                                          <figure>
                                             <img class="loadTemp" src="" :dta-src='this.$pathprod+"images/saint-ouen/saint-ouen-img-mask.png"' srcset="" :dta-srcset='this.$pathprod+"images/saint-ouen/saint-ouen-img-mask.png"' alt="cercle-brush">
                                          </figure>
                                       </div>
                                       <div class="ctn-parent">
                                          <div class="card-title">{{$t('dataIntersection')['13']}}</div> 
                                          <ul>
                                             <li class="list-item" v-for="secondItem in secondItems" :key="secondItem.id">
                                                <div class="ctn-item">
                                                   <div class="ctn-item-title">
                                                      <span class="title">{{ secondItem.title }}</span>
                                                   </div>
                                                   <div class="ctn-item-nbr">{{ secondItem.nbr }}'</div>
                                                </div>
                                             </li>
                                          </ul>
                                       </div>
                                    </div>
                                 </div>
                              </div>

                              <!--********************************-->
                              <div id="saint-ouen-vie" class="classParent saintOuen-vie" data-sect="sect3">
                                 <div class="sct-info">
                                    <h2 class="title-h2">{{$t('dataIntersection')['14']}}<br class="d-block">{{$t('dataIntersection')['15']}}</h2>
                                    <figure class="mobMap-img">
                                          <img class="loadTemp" src="" :dta-src='this.$pathprod+"images/saint-ouen/saintOuenMap-3.svg"' srcset="" :dta-srcset='this.$pathprod+"images/saint-ouen/saintOuenMap-3.svg"' alt="batiment-harmony-3">
                                    </figure>
                                 </div>
                                 <div class="sct-detail sct-detail-custom">
                                    <h4 class="title-h4 title-h4-spec">{{$t('dataIntersection')['16']}}</h4>
                                    <ul class="list-custom">
                                       <li v-for="thirdItem in thirdItems" :key="thirdItem.id"><i class="ico-circle" :class="thirdItem.color"></i>
                                          {{$t('dataRestauration')[thirdItem.id - 1]}}
                                       </li>
                                    </ul>
                                 </div>
                              </div>        
                           </div>

                           <!--*****************************************************-->
                           <div class="sct-right">
                              <div class="listMap">
                                 <figure class="map-harmony" data-img="sect1">
                                    <img class="imgPos" :src="$t('mobiliteDouce1')" alt="batiment-harmony-1">
                                 </figure>
                                 <figure class="map-harmony" data-img="sect2">
                                    <img class="imgPos" :src="$t('mobiliteDouce2')" alt="batiment-harmony-2">
                                 </figure>
                                 <figure class="map-harmony" data-img="sect3">
                                    <img class="imgPos" :src="$t('mobiliteDouce3')" alt="batiment-harmony-3">
                                 </figure>
                              </div>
                           </div>
                        </section>
                     </div>
                     <div ref="slide6" class="slide" data-anchor="ca-bouge">
                        <kinesis-container>
                           <section id="ca-bouge" class="page-section page-section-custom saintOuen-6-b">
                                 <div class="saintOuen-6">
                                    <div class="img-left">
                                       <div class="desk-img">
                                             <figure><kinesis-element :strength="5"><img class="loadTemp" src="" :dta-src='this.$pathprod+"images/saint-ouen/saintOuen-imgBouge-1.jpg"' srcset="" :dta-srcset='this.$pathprod+"images/saint-ouen/saintOuen-imgBouge-1-retina.jpg 2x"' alt="desk-imgBouge-1"></kinesis-element></figure>
                                             <figure><kinesis-element :strength="15"><img class="loadTemp" src="" :dta-src='this.$pathprod+"images/saint-ouen/saintOuen-imgBouge-2.jpg"' srcset="" :dta-srcset='this.$pathprod+"images/saint-ouen/saintOuen-imgBouge-2-retina.jpg 2x"' alt="desk-imgBouge-2"></kinesis-element></figure>
                                       </div>
                                       <div class="mob-img">
                                             <div class="mob-img-ctn">
                                                <figure><img class="loadTemp" src="" :dta-src='this.$pathprod+"images/saint-ouen/saintOuen-imgBouge-1.jpg"' srcset="" :dta-srcset='this.$pathprod+"images/saint-ouen/saintOuen-imgBouge-1.jpg"' alt="mob-imgBouge-1"></figure>
                                                <figure><img class="loadTemp" src="" :dta-src='this.$pathprod+"images/saint-ouen/saintOuen-imgBouge-3.jpg"' srcset="" :dta-srcset='this.$pathprod+"images/saint-ouen/saintOuen-imgBouge-3.jpg"' alt="mob-imgBouge-3"></figure>
                                             </div>
                                       </div>
                                    </div>
                                    <div class="sp-ParentBloc">
                                       <div class="sp-bloc">
                                             <h4 class="title-h4 anim-slide-left">{{$t('dataBouge')['0']}}</h4>
                                             <div class="sp-title">
                                                <h2 class="title-h2 anim-txt-focus">{{$t('dataBouge')['1']}}</h2>
                                                <p class="anim-slide-right">
                                                   <strong>{{$t('dataBouge')['2']}}</strong> {{$t('dataBouge')['3']}}
                                                </p>
                                                <p class="anim-slide-right">{{$t('dataBouge')['4']}} <strong>{{$t('dataBouge')['5']}}</strong>, {{$t('dataBouge')['6']}}</p>
                                             </div>
                                       </div>
                                    </div>
                                    <div class="img-right">
                                       <div class="desk-img">
                                             <figure><kinesis-element :strength="5"><img class="loadTemp" src="" :dta-src='this.$pathprod+"images/saint-ouen/saintOuen-imgBouge-3.jpg"' srcset="" :dta-srcset='this.$pathprod+"images/saint-ouen/saintOuen-imgBouge-3-retina.jpg 2x"' alt="desk-imgBouge-3"></kinesis-element></figure>
                                             <figure><kinesis-element :strength="25"><img class="loadTemp" src="" :dta-src='this.$pathprod+"images/saint-ouen/saintOuen-imgBouge-4.jpg"' srcset="" :dta-srcset='this.$pathprod+"images/saint-ouen/saintOuen-imgBouge-4-retina.jpg 2x"' alt="desk-imgBouge-4"></kinesis-element></figure>
                                       </div>
                                       <div class="mob-img">
                                             <div class="mob-img-ctn">
                                                <figure><img class="loadTemp" src="" :dta-src='this.$pathprod+"images/saint-ouen/saintOuen-imgBouge-2.jpg"' srcset="" :dta-srcset='this.$pathprod+"images/saint-ouen/saintOuen-imgBouge-2.jpg"' alt="mob-imgBouge-2"></figure>
                                                <figure><img class="loadTemp" src="" :dta-src='this.$pathprod+"images/saint-ouen/saintOuen-imgBouge-4.jpg"' srcset="" :dta-srcset='this.$pathprod+"images/saint-ouen/saintOuen-imgBouge-4.jpg"' alt="mob-imgBouge-4"></figure>
                                             </div>
                                       </div>
                                    </div>
                                 </div>
                           </section>
                        </kinesis-container>
                     </div>
                     <div ref="slide7" class="slide" data-anchor="envole-tertiaire">
                        <section id="envole-tertiaire" class="page-section section-saint-ouen-7 saintOuen-7" :class="{'increment': increment}">
                           <div class="envolTer__grid">
                                 <div class="sct-left">
                                    <h2 class="title-h2 desk-title-h2 anim-txt-focus">{{$t('titileTertiaire')}}</h2>
                                    <figure><img class="loadTemp" src="" :dta-src='this.$pathprod+"images/saint-ouen/saintOuen-Tertiaire.jpg"' srcset="" :dta-srcset='this.$pathprod+"images/saint-ouen/saintOuen-Tertiaire-retina.jpg 2x"' alt=""></figure>
                                 </div>
                           
                                 <div class="sct-right anim-slide-right">
                                    <div class="sct-right-box">
                                       <div class="sct-right-ctn">
                                             <h2 class="title-h2 mob-title-h2">{{$t('dataKeyChiffreTertiaire')['0']}}</h2>
                                             <div class="key-number-item mb-30">
                                                <p class="key-number"><span class="number-to-animate">
                                                <number
                                                   animationPaused
                                                   ref="number1"
                                                   :from="100"
                                                   :to="1400000"
                                                   :format="theFormat"
                                                   :duration="3"
                                                   easing="Power1.easeOut"
                                                />
                                                </span> m<span class="m2">2</span></p>
                                                <p class="key-desc">{{$t('dataKeyChiffreTertiaire')['1']}}</p>
                                             </div>
                                             <div class="key-number-item mb-30">
                                                <p class="key-number"><span class="number-to-animate">
                                                <number
                                                   animationPaused
                                                   ref="number2"
                                                   :from="100"
                                                   :to="95000"
                                                   :format="theFormat"
                                                   :duration="3"
                                                   easing="Power1.easeOut"
                                                /></span></p>
                                                <p class="key-desc">{{$t('dataKeyChiffreTertiaire')['2']}}</p>
                                             </div>
                                       </div>
                                    </div>

                                    <div class="sct-right-box">
                                       <div class="sct-right-ctn">
                                             <div class="mb-30">
                                                <h4 class="title-h4">{{$t('dataKeyChiffreTertiaire')['3']}}</h4>
                                                <p>{{$t('dataKeyChiffreTertiaire')['4']}}</p>
                                             </div>
                                             <div id="logoPartenaire" class="logo-partenaire">
                                                <a :href="image.ref" v-for="image in images" :key="image.id" rel="noopener" target="_blank">
                                                   <figure><img  v-bind:src="image.src" alt=""/></figure>
                                                </a>
                                             </div>
                                       </div>
                                    </div>
                                 </div>
                           </div>
                        </section>
                     </div>

                     <div ref="slide8" class="slide" data-anchor="page8">
                        <section id="footer-sct" class="page-section">
                           <div class="footer-sct-bloc">
                              <div class="sct-left custom-sct anim-slide-left">
                                 <div class="sct-left-txt">
                                    <h4 class="title-h4">{{$t('dataKeyChiffreTertiaire')['5']}}</h4>
                                    <p><strong>{{$t('dataKeyChiffreTertiaire')['6']}}</strong> {{$t('dataKeyChiffreTertiaire')['7']}}</p>
                                 </div>
                              </div>

                              <div class="sct-rightCustom">
                                 <div>
                                    <div class="key-number-item mb-30">
                                       <p class="key-number"><span class="number-to-animate">
                                       <number
                                          animationPaused
                                          ref="number3"
                                          :from="100"
                                          :to="300000"
                                          :format="theFormat"
                                          :duration="3"
                                          easing="Power1.easeOut"
                                       />
                                       </span> m<span class="m2">2</span></p>
                                       <p class="key-desc">{{$t('dataKeyChiffreTertiaire')['8']}}</p>
                                    </div>
                                    <div class="key-number-item mb-30">
                                       <p class="key-number"><span class="number-to-animate">
                                          <number
                                             animationPaused
                                             ref="number4"
                                             :from="100"
                                             :to="67000"
                                             :format="theFormat"
                                             :duration="3"
                                             easing="Power1.easeOut"
                                          />
                                          </span> m<span class="m2">2</span></p>
                                       <p class="key-desc">{{$t('dataKeyChiffreTertiaire')['9']}}</p>
                                    </div>
                                    <div class="key-number-item mb-30">
                                       <p class="key-number"><span class="number-to-animate" data-number="5000">
                                          <number
                                             animationPaused
                                             ref="number5"
                                             :from="0"
                                             :to="5000"
                                             :format="theFormat"
                                             :duration="3"
                                             easing="Power1.easeOut"
                                          />
                                          </span></p>
                                       <p class="key-desc">{{$t('dataKeyChiffreTertiaire')['10']}}</p>
                                    </div>
                                 </div>
                              </div>

                              <div class="anim-slide-right">
                                 <div>
                                    <div class="key-number-item mb-30">
                                       <p class="key-number"><span class="number-to-animate">
                                          <number
                                             animationPaused
                                             ref="number6"
                                             :from="0"
                                             :to="12"
                                             :format="theFormat"
                                             :duration="3"
                                             easing="Power1.easeOut"
                                          />
                                          </span> ha</p>
                                       <p class="key-desc">{{$t('dataKeyChiffreTertiaire')['11']}}</p>
                                    </div>
                                    <div class="key-number-item mb-30">
                                       <p class="key-number"><span class="number-to-animate">
                                          <number
                                             animationPaused
                                             ref="number7"
                                             :from="0"
                                             :to="68000"
                                             :format="theFormat"
                                             :duration="3"
                                             easing="Power1.easeOut"
                                          />
                                          </span> m<span class="m2">2</span></p>
                                       <p class="key-desc">{{$t('dataKeyChiffreTertiaire')['12']}}</p>
                                    </div>
                                 </div>
                              </div>
                           </div>

                           <div class="footer-sct-img">
                              <figure><img class="loadTemp" src="" :dta-src='this.$pathprod+"images/saint-ouen/saintOuen-footer.jpg"' srcset="" :dta-srcset='this.$pathprod+"images/saint-ouen/saintOuen-footer-retina.jpg 2x"' alt=""></figure>
                           </div>
                        </section>
                     </div>
                  </div>
               
               </full-page>
            
            </div>
         </div>
      </div>
      <div v-else :key="componentKey">
            <SaintOuenMobile/>
      </div>
   </div>
    
</template>

<script>
   import LottieAnimation from 'lottie-web-vue'
   import { KinesisContainer, KinesisElement} from 'vue-kinesis'
   // Observer.vue
   export default {
      name: 'SaintOuen',
      metaInfo() {
        return { 
            title: "Rhapsody - Saint-ouen énergie cinétique",
            meta: [
                { name: 'description', content:  'Suivre la ligne, RHAPSODY est accessible par la ligne 14, le RER C et en vélo. Ça bouge à saint-Ouen, venez découvrir le quartier autour de RHAPSODY'},
                { property: 'og:title', content: "Rhapsody - Saint-ouen énergie cinétique"},
                { property: 'og:site_name', content: 'Rhapsody'},
                { property: 'og:type', content: 'website'},    
            ]
        }
      },
      data(){
         return{
            componentKey: 0,
            scroll: null,
            showZoom: false,
            AnimtxtBlur: false,
            AnimLoc: false,
            saintOuen_5: false,
            saintOuen_6: false,
            saintOuen_7: false,
            bottomPos_saintouen5: null,
            options:{
               lockAnchors: true,
               slidesNavigation: true,
               navigation: true,
               slidesNavPosition: 'bottom',
               keyboardScrolling: true,
               loopHorizontal: false,
               anchors:["ec"],
               scrollingSpeed: 1500,
            },

            ///////////////////////////
            observer: null,
            isMove: false,
            firstItems:[
               {id:1, title:"Saint Lazare", subtitle:"pour relier la QCA et rayonner avec Eole!", nbr: "7'", subNbr: null},
               {id:2, title:"Madeleine", subtitle:"", nbr: "8'", subNbr: null},
               {id:3, title:"Porte Maillot", subtitle:"", nbr: "8'", subNbr: null},
               {id:4, title:"Châtelet", subtitle:"", nbr: "11'", subNbr: null},
               {id:5, title:"Invalides", subtitle:"pour changer de quais!", nbr: "25'", subNbr: null},
               {id:6, title:"La Défense", subtitle:"", nbr: "28'", subNbr: null},
               {id:7, title:"Gare de Lyon", subtitle:"", nbr: "13'", subNbr: null},
               {id:8, title:"Gare du Nord", subtitle:"", nbr: "20'", subNbr: null},
               {id:9, title:"Gare Montparnasse", subtitle:"", nbr: "29'", subNbr: null},
               {id:10, title:"Roissy CDG TGV", subtitle:"", nbr: "43' > 25'", subNbr: "(2030)"},
               {id:11, title:"Aéroport d'Orly", subtitle:"", nbr: "59' > 37'", subNbr: "(2024-25)"}
            ],
            secondItems:[
               {id:1, title:"Saint Lazare", nbr: "15'"},
               {id:2, title:"Montmartre", nbr: "20'"},
               {id:3, title:"Rue de Rivoli", nbr: "22'"},
               {id:4, title:"Canal Saint-Malin", nbr: "25'"},
               {id:5, title:"République", nbr: "26'"},
               {id:6, title:"Châtelet", nbr: "27'"}
            ],
            thirdItems:[
               {id:1, title:"Commerces à venir", color:"color-red"},
               {id:2, title:"Snacks", color:"color-lighBlue"},
               {id:3, title:"Restaurants", color:"color-orange"},
               {id:4, title:"Hypermarchés", color:"color-purple"},
               {id:5, title:"Fitness", color:"color-pink"},
               {id:6, title:"Librairie", color:"color-brown"},
               {id:7, title:"Healthy food", color:"color-green"},
               {id:8, title:"Galerie commerciales", color:"color-yellow"},
               {id:9, title:"Pharmacie", color:"color-grey"}
            ],
            ////////////////////////////

            increment: false,
            showBrushK:false,
            images:[
                {id:1, src:"/images/logo/logo-allianz.svg", ref: "https://www.allianz.com"},
                {id:2, src:"/images/logo/logo-Alstom.svg", ref: "https://www.alstom.com"},
                {id:3, src:"/images/logo/logo-amazon.svg", ref: "https://www.amazon.com"},
                {id:4, src:"/images/logo/logo-bic.svg", ref: "https://www.bic.com"},
                {id:5, src:"/images/logo/logo-oreal.svg", ref: "https://www.oreal.com"},
                {id:6, src:"/images/logo/logo-monoprix.svg", ref: "https://www.monoprix.com"},
                {id:7, src:"/images/logo/logo-ocp.svg", ref: "https://www.ocp.com"},
                {id:8, src:"/images/logo/logo-samsung.svg", ref: "https://www.samsung.com"}
            ]

         }
      },
      created () {
         window.addEventListener('wheel', this.increaseNumber);
         window.addEventListener('resize', this.handleresize);
      },
      destroyed () {
         window.removeEventListener('wheel', this.increaseNumber);
         window.removeEventListener('resize', this.handleresize);
      },
      components: {
         SaintOuenMobile: () => import('@/components/SaintOuenMobile.vue'),
         VideoSaintOuen: () => import('@/components/video/VideoSaintOuen.vue'),
         LottieAnimation,
         KinesisContainer,
         KinesisElement
      },

      methods: {
         scrollToNext(){
            document.querySelector('.fp-next').click();
            this.beginAnimationZoom()
         },

         /*Fonction beginAnimationZoom*/
         beginAnimationZoom(){
            this.showZoom = true;
            setTimeout(() => {
               var showCircle = document.querySelector(".loc-bloc-circle")
               showCircle.style.opacity = "1"
               setTimeout(() => {
                    this.AnimLoc = true;
               }, 600);
            }, 500);
         },

         wheelGlobal(){
            if(document.querySelectorAll(".fp-slide")[1].classList.contains("active")){this.beginAnimationZoom()}
         },

         isMobile() {
            if( screen.width < 1025 ) {
               return true;
            }
            else {
               return false;
            }
         },

         handleresize(){
            this.componentKey =+ 1;
            this.isMobile();
         },

         //init lib fullpage if components is ready
         componentsReady() {
            setTimeout(() => {
               this.AnimtxtBlur = true;
            }, 2000);
         },

         increaseNumber(e){
            let saintOuen7 = document.querySelector('#envole-tertiaire');
            let parentBox = saintOuen7.parentElement
            let activeBloc = parentBox.parentElement
            if(activeBloc.classList.contains('active')){
               setTimeout(() => {
                  this.$refs.number1.play()
                  this.$refs.number2.play()
               }, 200);
            }
            
            if(this.$refs.slide8.classList.contains('active')){
               this.$refs.number3.play()
               this.$refs.number4.play()
               this.$refs.number5.play()
               this.$refs.number6.play()
               this.$refs.number7.play()
            }


            //Simule clique button onScroll Top/Bottom
            if(window.matchMedia('(min-device-width: 1200px)').matches){
               var section5 = document.querySelectorAll('.fp-table')[4].classList.contains("active")
               if(e.deltaY < 0){
                  if(section5){
                     return
                  }else{
                     document.querySelector('.fp-prev').click();
                  }
               }
               else{
                  if(section5){
                     return
                  }else{
                     document.querySelector('.fp-next').click();
                  }
               }
            }
         },
         anchorSlide(){
            //Gestion anchor in dot btn
            var dotList = document.querySelectorAll(".fp-slidesNav .fp-sr-only")

            if(dotList){
               for (const dot of dotList) {
                  var txt_anchor = dot.textContent
                  dot.parentElement.href = "#" + txt_anchor
               }
            }
         },
         ///////////////////////////////////

         stopBlockPropagation(e){
            e.stopPropagation();
         },
         isWheel(){
            this.isMove = true;
            setTimeout(() => {
               this.isMove = false;
            }, 1500);
         },
         wheelIntersection(e){
            e.stopPropagation();
            //** add scrollable section /
               var ParentEl = document.getElementById("saintOuen-5").parentElement
               ParentEl.classList.add("page-section-scrollable")
            //** End */
            let keyNumberBlock = document.getElementById('saintOuen-5');
            var sctBottom = Math.floor(keyNumberBlock.scrollHeight - keyNumberBlock.offsetHeight)


            if(this.isMove){
               return
            }else{
               if(e.deltaY < 0){
                  this.isWheel()
                  setTimeout(() => {
                     if(keyNumberBlock.scrollTop === 0){
                        document.querySelector('.fp-prev').click();
                     }
                  }, 500);
               }
               else{
                  this.isWheel()
                  var old_value = Math.floor(keyNumberBlock.scrollTop)
                  var tmp_value = old_value
                  old_value = this.bottomPos_saintouen5
                  this.bottomPos_saintouen5 = tmp_value

                  if(Math.floor(keyNumberBlock.scrollTop) >= sctBottom || old_value == this.bottomPos_saintouen5 && old_value != 0){
                     document.querySelector('.fp-next').click();
                  }
                  else{
                     this.stopBlockPropagation(e);
                  }
               }
            }
         },

         secondCircleAnim(){
               this.$refs.secondCircleAnim.play();
         },
         //////////////////////////////

         theFormat(number) {
               return this.numberWithSpaces(number.toFixed(0));
         },

         numberWithSpaces(x) {
               return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
         },

         playAnimation(){
            var inter = setInterval(() => {
               if(window.location.hash == "#envole-tertiaire"){
                  setTimeout(() => {
                        this.$refs.number1.play()
                        this.$refs.number2.play()
                  }, 500);
               }
            }, 1000);
            window.addEventListener("wheel", function(){
               clearInterval(inter)
            })
         },
         lazyLoading(){
            /*Diffère tous les images*/
            setTimeout(function(){
               let loadImg = document.querySelectorAll('.loadTemp');
               for(let i = 0; i < loadImg.length; i++){
                  loadImg[i].setAttribute('srcset',loadImg[i].getAttribute('dta-srcset'));
                  loadImg[i].setAttribute('src',loadImg[i].getAttribute('dta-src'));
               }
            }.bind(this), 1200);
         }
      },

      mounted() {

         //Load asynchrone on componentsReady
         setTimeout(() => {
            if(window.matchMedia('(min-device-width: 1200px)').matches){
               this.componentsReady()
            }
            this.anchorSlide()
         }, 1500);

         //Animation Txt display in first Page saintouen
         document.body.classList.remove('removeHiddenOverflow');

         //Play entete video if component is ready
         setTimeout(() => {
            var video = document.querySelector("#saintOuen-1 .bg-hero")
            if(video){
               video.play()  
               
               var inter = setInterval(() =>{
                  if(video.paused && video.ended == false ){
                     video.play()
                  }
               }, 500);
               
               video.addEventListener("ended", function(){
                  clearInterval(inter)
               })
            }
         }, 1500);

        

         /////////////////////////////////////:
         var parentEl = document.querySelectorAll(".sct-left .classParent");
         var listImg = document.querySelectorAll(".sct-right .map-harmony");            
     
         //****************************** */
         //Change sticky image
         //****************************** */
         const observer = new IntersectionObserver(([entry]) => {
            if (entry && entry.isIntersecting) {
               for(let itemImg of listImg) {
                  if(itemImg.getAttribute('data-img') === entry.target.getAttribute('data-sect')) {
                     itemImg.classList.add('openMap');
                     for(let nothis of listImg) {
                        if(nothis!==itemImg) nothis.classList.remove('openMap');
                     }
                  }
               }               
            }
         });
         for(let item of parentEl) {
            observer.observe(item);
         }

         //****************************** */
         //Circle Brush Animation
         //****************************** */
         var circleBlocParent1 = document.querySelectorAll(".card-item");

          const observer2 = new IntersectionObserver(([entry]) => {
            if (entry && entry.isIntersecting) {
               if(entry.target.getAttribute('data-view') == "firstCircleAnim"){
                  setTimeout(() => {
                     this.$refs.firstCircleAnim.play();
                  }, 200);
               }else if(entry.target.getAttribute('data-view') == "secondCircleAnim"){
                  setTimeout(() => {
                     this.$refs.secondCircleAnim.play();
                  }, 200);
               }            
            }
         });
         for(let item of circleBlocParent1) {
            observer2.observe(item);
         }

         //****************************** */
         // Increase number Mobile
         //****************************** */
         var circleBlocParent2 = document.querySelectorAll(".sct-right");

         const observer3 = new IntersectionObserver(([entry]) => {
            if( screen.width <= 1200 ) {
               if (entry && entry.isIntersecting) {
                  setTimeout(() => {
                     this.playAnimation();
                  }, 200);          
               }
            }
         });
         for(let item of circleBlocParent2){
            observer3.observe(item);
         }

         //INCREASE NUMBER AFTER LOAD
         document.addEventListener('readystatechange', () => { 
            if (document.readyState =='complete') {
               this.playAnimation()
            }
         })
         this.playAnimation()
         this.lazyLoading()

         //OnreSize reinject all img
         window.addEventListener("resize", function(){
            /*Diffère tous les images*/
            setTimeout(function(){
               let loadImg = document.querySelectorAll('.loadTemp');
               for(let i = 0; i < loadImg.length; i++){
                  loadImg[i].setAttribute('srcset',loadImg[i].getAttribute('dta-srcset'));
                  loadImg[i].setAttribute('src',loadImg[i].getAttribute('dta-src'));
               }
            }.bind(this), 1200);
         })

         //Animation map

 
      }
      
   };

</script>